import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";

import { Contract } from "../../pages/contracts/types";
import { DiscountSelection } from "../../pages/discounts/DiscountSelection";
import { OfferSelection } from "../../pages/offers/OfferSelection";
import { Offer } from "../../pages/offers/types";
import { CountryCurrency, Customer } from "../../utils/commons";
import { FormTextField } from "../form/input/text-field/FormTextField";
import { FormProps } from "../form/use-form";

type Props = {
  itemKey: number,
  identifierName: string,
  propertyName: string,
  customer?: Customer,
  offers: Offer[],
  contracts?: Contract[],
  selectedOffer?: Offer | Contract,
  isOffersLoading: boolean,
  form: FormProps<any>,
  handleSelectContract?: (isSelected: boolean) => void
};

export const OrderLine : React.FC<Props> = ({
  itemKey,
  identifierName,
  propertyName,
  customer,
  offers,
  contracts,
  selectedOffer,
  isOffersLoading,
  form
}) => {
  const isOfferSelected = !selectedOffer || "offerType" in selectedOffer;
  return (
    <>
      <Grid item xs={12} key={itemKey}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <OfferSelection
              form={form}
              identifierName={identifierName}
              lineItemName ={`${propertyName}[${itemKey}]`}
              offers={offers}
              contracts={contracts}
              isLoading={isOffersLoading}
            />
          </Grid>
          <Grid item xs={3}>
            <DiscountSelection
              name={`${propertyName}[${itemKey}]`}
              label="Discount"
              selectedOffer={selectedOffer as Offer}
              disabled={!isOfferSelected}
              form={form}
            />
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              name={`${propertyName}[${itemKey}].quantity`}
              label="Quantity"
              type="number"
              required
              form={form}
              disabled={!isOfferSelected}
              dependencies={[`${propertyName}[${itemKey}].${identifierName}`]}
            />
          </Grid>
          <Grid item xs={2}>
            <FormTextField
              name={`${propertyName}[${itemKey}].amount`}
              label="Price per unit"
              type="number"
              required
              form={form}
              disabled={isOfferSelected}
              InputProps={{
                endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={2}
            sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
            <Button
              sx={{ mt: 1 }}
              disabled={form.value[propertyName].length === 1}
              onClick={() => form.removeArrayElement(`${propertyName}`, itemKey)}
              startIcon={<DeleteIcon/>}>
                Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};