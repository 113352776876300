import React from "react";

import { Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  FunctionField, ReferenceField,
  SimpleShowLayout,
  TextField, useShowController
} from "react-admin";

import { UserRoles } from "../../../core/providers/auth/roles";
import { Resources } from "../../../resources";
import {
  Device, DeviceStatus,
  StoveModel
} from "../../../utils/commons";
import {
  countryNameRenderer,
  customerRenderer, deviceStatusIcons,
  enumIconRenderer,
  enumRenderer
} from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { DecommissionStoveButton } from "../action/DecommisionStoveButton";

export const StoveMainTab : React.FC = () => {
  const { hasAccess: isAdmin } = useCheckAccess(UserRoles.ROLE_SUPAMOTO_ADMIN);
  const { record } = useShowController<Device>();

  const activeStove = record && record.stoves?.find((stove) => stove.status === "ACTIVE");
  const companyId = record?.stoves?.find((stove) => stove.companyId)?.companyId;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <TextField source="id" label="Internal ID"/>
          <TextField source="deviceId" label="Device ID"/>
          <TextField source="nativeId" label="Stove UID"/>
          <FunctionField label="Model"
            render={(record: Device) => enumRenderer(record.model, StoveModel)}/>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={4}>
        <SimpleShowLayout>
          <FunctionField label="Country"
            render={() => activeStove && countryNameRenderer(activeStove) || "-"}/>
          <FunctionField label="Customer"
            render={() => activeStove && activeStove.customer && customerRenderer(activeStove.customer) || "-"}/>
          {companyId && (
            <ReferenceField label="Company" reference={Resources.Companies} source="companyId" record={{ companyId }} sortable={false}>
              <TextField source="name"/>
            </ReferenceField>
          )}
          <FunctionField label="Status"
            render={(record: Device) => enumIconRenderer(record.status, DeviceStatus, deviceStatusIcons)}/>
        </SimpleShowLayout>
      </Grid>
      {isAdmin &&
          <Grid item xs={4}>
            <SimpleShowLayout>
              <FunctionField label="Nft Id"
                render={(record: Device) => record.nftId || "-"}/>
              <FunctionField
                label="Nft Collection Name"
                render={(record: Device) => record.nftStoveCollection?.name || "-"
                }
              />
              <FunctionField label="Did"
                render={(record: Device) => record.did || "-"}/>
              <FunctionField label="Ixo Address"
                render={(record: Device) => record.ixoAddress || "-"}/>
            </SimpleShowLayout>
          </Grid>
      }
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      { record && (
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ px: 2, py: 1 }}>
            <DecommissionStoveButton
              stove={activeStove}
              disabled={record.status !== "ACTIVE" || activeStove !== undefined && activeStove.customer !== undefined}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};