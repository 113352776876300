import * as React from "react";

import { Box } from "@mui/material";
import { AppBar as RaAppBar } from "react-admin";

import { AdjustmentCount } from "../../adjustment/AdjustmentCount";
import { UserRoles } from "../../core/providers/auth/roles";
import { useCheckAccess } from "../../utils/use-check-access";

export const AppBar: React.FC = (props: any) => {
  const { hasAccess: hasAnyAccess } = useCheckAccess(Object.values(UserRoles));

  return (
    <RaAppBar {...props} color="primary">
      <Box sx={{ flexGrow: 1 }} />
      {hasAnyAccess && <AdjustmentCount/>}
    </RaAppBar>
  );
};