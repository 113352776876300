import { useEffect, useMemo, useState } from "react";

import { isNil } from "lodash-es";
import { useNotify } from "ra-core";
import { useDataProvider, useStore } from "react-admin";

import { Offer, OfferType } from "../../pages/offers/types";

export const useCustomerEligibleOffers = (customerId?: number, types?: Array<keyof typeof OfferType>) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useStore<Offer[]>("api.offers", undefined);

  useEffect(() => {
    if (!customerId) return;

    if (isNil(offers)) {
      setIsLoading(true);
      dataProvider.getManyByUrl(`customers/${customerId}/offers`)
        .then((response: { data: Offer[]}) => {
          setOffers(response.data);
          return response;
        })
        .catch(() => notify("Error: failed to retrieve offers", { type: "error" }))
        .finally(() => setIsLoading(false));
    }
  }, [customerId, dataProvider, notify, offers, setOffers]);

  const offersByType = useMemo(() => {
    return (offers || []).filter((offer) => isNil(types) ? true : types.includes(offer.offerType));
  }, [offers, types]);

  return { offers: offersByType, isLoading: isLoading };
};