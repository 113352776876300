import React, { useCallback, useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Alert from "@mui/material/Alert";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { isNil } from "lodash-es";
import moment from "moment";
import { useNotify } from "ra-core";
import { useDataProvider, useShowController } from "react-admin";
import { Link } from "react-router-dom";

import { useUsers } from "../../../components/user/use-users";
import { UserRoles } from "../../../core/providers/auth/roles";
import { CountryCurrency, FullCustomer, Stove } from "../../../utils/commons";
import { DateTimeFormat, formatDate, formatDateTime } from "../../../utils/date-time-utils";
import {
  contractDuration,
  enumChipRenderer,
  enumRenderer,
  usernameRenderer
} from "../../../utils/field-renderers";
import { useCheckAccess } from "../../../utils/use-check-access";
import { CancelContractButton } from "../../contracts/actions/CancelContractButton";
import { ReactivateContractButton } from "../../contracts/actions/ReactivateContractButton";
import { RepossessButton } from "../../contracts/actions/RepossessButton";
import { SwapDeviceButton } from "../../contracts/actions/SwapDeviceButton";
import PaymentSchedule from "../../contracts/PaymentSchedule";
import {
  Contract,
  ContractPaymentStatus,
  contractPaymentStatusChipColors,
  ContractStatus,
  contractStatusChipColors, ContractType
} from "../../contracts/types";
import { CustomerTabProps, GetContractsResponse } from "../types";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3
  }
}));

const isNonUtilityContractFullyPaid = (contract: Contract): boolean => {
  return contract.totalAmount <= contract.paidAmount && contract.type === "NON_UTILITY";
};

const isMoreThanSevenDaysAgo = (dateString: string): boolean => {
  const date = moment(dateString);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(date, "days");
  return differenceInDays > 7;
};

export const CustomerContractsTab: React.FC<CustomerTabProps> = ({
  customerId
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record: customer } = useShowController<FullCustomer>();
  const [contracts, setContracts] = useState<Contract[]>();
  const [processingContract, setProcessingContract] = useState<number | undefined>();
  const { users } = useUsers();
  const [isContractsLoading, setContractsLoading] = useState(false);
  const { hasAccess } = useCheckAccess([ UserRoles.ROLE_SUPAMOTO_ADMIN ]);

  const fetchContracts = useCallback(() => {
    setContractsLoading(true);
    return dataProvider.getManyByUrl(`customers/${customerId}/contracts`)
      .then((response: GetContractsResponse) => {
        setContracts(response.data);
        return response;
      })
      .catch(() => {
        notify("Error: failed to load customer contracts", { type: "error", autoHideDuration: 5000 });
      }).finally(() => {
        setContractsLoading(false);
      });
  }, [dataProvider, customerId, notify]);

  const onContractChange = (contractId: number) => {
    setProcessingContract(contractId);
    fetchContracts()
      .finally(() => {
        setProcessingContract(undefined);
      });
  };

  useEffect(() => {
    if (!customerId) return;
    fetchContracts();

  }, [customerId, fetchContracts]);

  if ((isContractsLoading && !processingContract) || !customer) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <CircularProgress size={30}/>Fetching
      </Box>
    );
  }

  if (contracts && contracts.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">No contracts found</Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {contracts?.map((contract) => (
        <Accordion
          key={contract.id}
          defaultExpanded={contract.status === "ACTIVE"}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">Contract #{contract.id} {enumChipRenderer(contract.status, ContractStatus, contractStatusChipColors)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              { processingContract === contract.id && <Grid item
                xs={12}
                sx={{
                  zIndex: 9999,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)"
                }}
              >
                <CircularProgress/>
              </Grid>}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Contract information</Typography>
              </Grid>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>Number:</Grid>
                    <Grid item xs={6}>{contract.id}</Grid>

                    <Grid item xs={6}>Duration:</Grid>
                    <Grid item xs={6}>{contractDuration(contract.duration)}</Grid>

                    <Grid item xs={6}>Type:</Grid>
                    <Grid item xs={6}>{enumRenderer(contract.type, ContractType)}</Grid>

                    <Grid item xs={6}>Contract Status:</Grid>
                    <Grid item xs={6}>
                      {enumChipRenderer(contract.status, ContractStatus, contractStatusChipColors)}
                    </Grid>

                    <Grid item xs={6}>Payment Status:</Grid>
                    <Grid item xs={6}>{enumChipRenderer(
                      contract.totalAmount > contract.paidAmount ? "INCOMPLETE" : "COMPLETED",
                      ContractPaymentStatus, contractPaymentStatusChipColors)}</Grid>

                    <Grid item xs={6}>Effective Date:</Grid>
                    <Grid item xs={6}>{formatDate(contract.effectiveDate, DateTimeFormat.CUSTOM_DATE)}</Grid>

                    <Grid item xs={6}>Created Date:</Grid>
                    <Grid item xs={6}>{formatDateTime(contract.timeCreated)}</Grid>

                    {contract.defaultDate && (
                      <>
                        <Grid item xs={6}>Default Date:</Grid>
                        <Grid item xs={6}>{formatDateTime(contract.defaultDate)}</Grid>
                      </>
                    )}
                    
                    <Grid item xs={6}>Created By:</Grid>
                    <Grid item xs={6}>{usernameRenderer(users.find((user) => user.id === contract.createdBy))}</Grid>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Grid item xs={12} sm={6}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pt: 0 }}>Product</TableCell>
                          <TableCell sx={{ pt: 0 }}>Device ID</TableCell>
                          <TableCell sx={{ pt: 0 }}>Condition Type</TableCell>
                          <TableCell sx={{ pt: 0 }}>Discount</TableCell>
                          <TableCell sx={{ pt: 0 }}>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contract.details.map((product) => (
                          <TableRow key={product.id}>
                            <TableCell>{product.offer.name}</TableCell>
                            <TableCell>
                              {product.instanceId ? (
                                <Link to={`/stoves/devices/${product.instanceId}/show`} target="_blank">
                                  {customer.stoves.find((stove : Stove) => stove.instanceId === product.instanceId)?.deviceId || "-"}
                                </Link>
                              ) : "-"}
                            </TableCell>
                            <TableCell>
                              {!isNil(product.conditionType) ? (
                                product.conditionType === "NEW" ? (
                                  <Chip label="New" color="success" variant="outlined" clickable size="small" />
                                ) : (
                                  <Chip label="Pre-Owned" color="warning" variant="outlined" clickable size="small" />
                                )
                              ) : "-"}
                            </TableCell>
                            <TableCell>{product.discount?.name || "-"}</TableCell>
                            <TableCell>{product.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>Contract progress</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={(contract.paidAmount / contract.totalAmount) * 100}
                          sx={{ my: 2 }}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >{`${Math.round((contract.paidAmount / contract.totalAmount) * 100)}%`}</Typography>
                      </Box>
                    </Box>

                    <Typography variant="body2">
                      Paid <span style={{ fontWeight: 700 }}>{contract.paidAmount}{" "}{CountryCurrency[customer?.country]}</span> out of {" "}
                      <span style={{ fontWeight: 700 }}>{contract.totalAmount}{" "}{CountryCurrency[customer?.country]}</span>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <PaymentSchedule
                      customer={customer}
                      downPayment={contract.downPayment}
                      duration={contract.duration}
                      totalAmount={contract.totalAmount}
                      effectiveDate={contract.effectiveDate}
                      paidAmount={contract.paidAmount}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ px: 2, py: 1 }}>
                  {customer && (
                    <RepossessButton
                      contract={contract}
                      disabled={!hasAccess || contract.status !== "ACTIVE" || isNonUtilityContractFullyPaid(contract)}
                      onContractChange={onContractChange}
                    />)}
                </Box>
                <Box sx={{ px: 2, py: 1 }}>
                  <CancelContractButton
                    disabled={!hasAccess || contract.status !== "ACTIVE" ||
                        (contract.paidAmount - contract.downPayment) > 0
                        || isNonUtilityContractFullyPaid(contract)
                        || isMoreThanSevenDaysAgo((contract.timeCreated))
                    }
                    contract={contract}
                    onContractChange={onContractChange}
                  />
                </Box>
                <Box sx={{ px: 2, py: 1 }}>
                  <SwapDeviceButton
                    customer={customer}
                    contract={contract}
                    disabled={!hasAccess || !customer || contract.status !== "ACTIVE"
                      || !contract.details.some((detail) => detail.instanceId !== undefined)}
                    onContractChange={onContractChange}
                  />
                </Box>
                {
                  contract.status !== "ACTIVE" && contract.status !== "CANCELLED" && (
                    <Box sx={{ px: 2, py: 1 }}>
                      <ReactivateContractButton
                        contract={contract}
                        disabled={isMoreThanSevenDaysAgo(contract.defaultDate)}
                        onContractChange={onContractChange}
                      />
                    </Box>
                  )
                }
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
