import React from "react";

import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField
} from "react-admin";

import { useCustomerFilters } from "../../components/filter/customer-filters";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { UserRoles } from "../../core/providers/auth/roles";
import { Resources } from "../../resources";
import { CountryCurrency, Customer } from "../../utils/commons";
import {
  contractDuration,
  customerRenderer,
  enumChipRenderer, enumRenderer
} from "../../utils/field-renderers";
import { ContractDetailsList } from "./ContractDetailsList";
import { ListCreateContractButton } from "./list-actions/ListCreateContractButton";
import {
  Contract,
  ContractPaymentStatus,
  contractPaymentStatusChipColors, ContractStatus,
  contractStatusChipColors, ContractType
} from "./types";
import { useContractsFilters } from "./use-contracts-filters";

export const ContractsList: React.FC = () => {
  const filters = [
    ...useCustomerFilters({
      groupsAlwaysOn: true
    }),
    ...useContractsFilters()
  ];
  return <List filters={filters}
    actions={
      <PresetListActions additionalExportRoles={[UserRoles.ROLE_SALES]}>
        <ListCreateContractButton/>
      </PresetListActions>
    }
    pagination={<DefaultPagination/>}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
  >
    <DatagridConfigurable
      bulkActionButtons={false}
      expand={<ContractDetailsList/>}>
      <TextField source="id"/>
      <ReferenceField
        label="Customer"
        reference={Resources.Customers}
        source="customerId"
        link={false}
        sortable={false}>
        <FunctionField render={(customer: Customer) => customerRenderer(customer)}/>
      </ReferenceField>
      <FunctionField source="type"
        label="Contract type"
        sortable={false}
        render={(record: Contract) => enumRenderer( record.type, ContractType)}/>
      <FunctionField
        source="totalAmount"
        label="Total Amount"
        sortable={false}
        render={(record: Contract) =>
          <ReferenceField
            reference={Resources.Customers}
            source="customerId"
            link={false}
          >
            <FunctionField render={(customer: Customer) => `${record.totalAmount} ${CountryCurrency[customer.country] ?? ""}`} />
          </ReferenceField>
        }
      />
      <FunctionField
        sortable={false}
        source="paymentDue"
        label="Payment Due"
        render={(record: Contract) =>
          <ReferenceField
            source="customerId"
            reference={Resources.Customers}
            link={false}
          >
            <FunctionField render={(customer: Customer) =>
              `${(record.totalAmount - record.paidAmount).toFixed(2)} ${customer.wallet.currency}`} />
          </ReferenceField>
        }
      />
      <FunctionField
        source="duration"
        label="Duration"
        sortable={false}
        render={(record: Contract) => contractDuration(record.duration)}/>
      <FunctionField label="Contract Status"
        render={(record: Contract) => enumChipRenderer(record.status, ContractStatus, contractStatusChipColors)}/>
      <FunctionField label="Payment Status"
        render={(record: Contract) => enumChipRenderer(record.totalAmount > record.paidAmount ? "INCOMPLETE" : "COMPLETED", ContractPaymentStatus, contractPaymentStatusChipColors)}/>
      <DateField source="effectiveDate" label="Effective date"/>
      <DateField source="timeCreated" label="Creation date" showTime/>
    </DatagridConfigurable>
  </List>;
};