import React, { useEffect, useMemo } from "react";

import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";

import { useGetDiscountsByOffer } from "../../components/discount/use-get-discounts-by-offer";
import { FormAutocomplete } from "../../components/form/input/autocomplete/FormAutocomplete";
import { FormProps } from "../../components/form/use-form";
import { calculatePrice } from "../../components/offer/utils";
import { Offer } from "../offers/types";
import { Discount } from "./types";

type DiscountSelectionProps = {
  name: string,
  label: string,
  selectedOffer?: Offer,
  form: FormProps<any>,
  disabled?: boolean
};

const onDiscountChange = (
  form:FormProps<any>,
  lineItemName: string,
  discount: Discount | null,
  reason: AutocompleteChangeReason,
  selectedOffer?: Offer) => {
  if (reason === "selectOption" && discount) {
    form.setValueByLabel(`${lineItemName}.discountId`, discount.id);
    if (selectedOffer) {
      const price = calculatePrice(selectedOffer, discount);
      form.setValueByLabel(`${lineItemName}.amount`, price);
    } else {
      form.setValueByLabel(`${lineItemName}.amount`, null);
    }
  }
  if (reason === "clear") {
    form.setValueByLabel(`${lineItemName}.discountId`, null);
    form.setValueByLabel(`${lineItemName}.amount`, selectedOffer?.price || null);
  }
};

export const DiscountSelection: React.FC<DiscountSelectionProps> = ({
  name,
  label,
  selectedOffer,
  form,
  disabled = false
}) => {
  const { getDiscountsByOffer, isLoading, fetchDiscounts } = useGetDiscountsByOffer();
  const discountsByOffer = useMemo(() => getDiscountsByOffer(selectedOffer?.id),
    [getDiscountsByOffer, selectedOffer?.id]);

  useEffect(() => {
    fetchDiscounts(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormAutocomplete
      name={`${name}.discountId`}
      label={label}
      form={form}
      isLoading={isLoading}
      disabled={disabled}
      options={discountsByOffer}
      onChange={(_, discount: Discount, reason: AutocompleteChangeReason) => {
        onDiscountChange(form, name, discount, reason, selectedOffer);
      }}
      getOptionLabel={(discount: Discount) => discount.name}
      renderOption={(discount: Discount) => discount.name}
    />
  );
};
