import React, { SyntheticEvent, useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, CircularProgress, Divider, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash-es";
import { useNotify } from "ra-core";
import { useDataProvider } from "react-admin";

import { useCreateContract } from "../../pages/contracts/CreateContractProvider";
import { ContractType } from "../../pages/contracts/types";
import { DiscountSelection } from "../../pages/discounts/DiscountSelection";
import { OfferSelection } from "../../pages/offers/OfferSelection";
import { Offer } from "../../pages/offers/types";
import { Resources } from "../../resources";
import { CountryCurrency, Device } from "../../utils/commons";
import { FormAutocomplete } from "../form/input/autocomplete/FormAutocomplete";
import { FormTextField } from "../form/input/text-field/FormTextField";
import { StoveStateIcon } from "./StoveStateIcon";

type ProductLineItemProps = {
  index: number,
  offers: Offer[],
  selectedOffer?: Offer,
  isOffersLoading: boolean,
  handleSelectDevice?: (device: Device) => void,
  contractType?: ContractType
};

export const ContractLineItem : React.FC<ProductLineItemProps> = ({
  index,
  offers,
  selectedOffer,
  isOffersLoading,
  handleSelectDevice,
  contractType
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { form, customer } = useCreateContract();
  const [isStovesLoading, setIsStovesLoading] = useState(false);
  const [stoves, setStoves] = useState<Device[]>([]);
  const [selectedStove, setSelected] = useState("");
  const [deviceMap, setDeviceMap] = useState<Record<number, Device>>({});

  useEffect(() => {
    if (isEmpty(selectedStove)) return;

    setIsStovesLoading(true);
    dataProvider.getList(Resources.Devices, {
      pagination: {
        page: 1,
        perPage: 10
      },
      sort: {
        field: "id",
        order: "DESC"
      },
      filter: {
        queryText: [selectedStove]
      }
    }).then((response) => {
      setStoves(response.data);
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stoves", { type: "error" });
    }).finally(() => {
      setIsStovesLoading(false);
    });
  }, [selectedStove, dataProvider, notify]);

  const isUtilityContract = contractType === ContractType.UTILITY;

  const onDeviceSelected = (index: number) => (_event: SyntheticEvent, device: Device) => {
    if (device && handleSelectDevice) {
      setDeviceMap((prevMap) => ({
        ...prevMap,
        [index]: device
      }));
      form.setValueByLabel(`details[${index}].instanceId`, device.id);
      handleSelectDevice(device);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={isUtilityContract ? 4 : 3}>
            <OfferSelection
              form={form}
              identifierName="offerId"
              lineItemName ={`details[${index}]`}
              offers={offers}
              isLoading={isOffersLoading}
            />
          </Grid>
          { selectedOffer && selectedOffer.offerType === "STOVE" && (
            <Grid item xs={isUtilityContract ? 6 : 5}>
              <FormAutocomplete
                disableClearable
                inputValue={selectedStove}
                options={stoves}
                getOptionLabel={(device: Device) => `${device.deviceId}`}
                onChange={onDeviceSelected(index)}
                onInputChange={(_, newInputValue) => setSelected(newInputValue)}
                renderOption={(device: Device) => device.deviceId}
                name={`details[${index}].instanceId`}
                label="Enter Device ID *"
                form={form}
                filterOptions={(options) => options}
                endAdornment={
                  <>
                    {
                      isStovesLoading ?
                        <CircularProgress color="inherit" size={20} /> :
                        (form.value.details[index].instanceId && !isEmpty(selectedStove) ?
                          <>
                            <StoveStateIcon device={deviceMap[index]}/>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                          </> :
                          null
                        )
                    }
                  </>
                }
              />
            </Grid>
          ) || (
            <>
              <Grid item xs={isUtilityContract ? 4 : 3}>
                <DiscountSelection
                  name={`details[${index}]`}
                  label="Discount"
                  selectedOffer={selectedOffer as Offer}
                  disabled={!selectedOffer}
                  form={form}
                />
              </Grid>
              <Grid item xs={2}>
                <FormTextField
                  name={`details[${index}].quantity`}
                  label="Quantity"
                  type="number"
                  required
                  form={form}
                />
              </Grid>
            </>
          )
          }
          {!isUtilityContract && (
            <Grid item xs={2}>
              <FormTextField
                name={`details[${index}].amount`}
                label="Price per unit"
                type="number"
                required
                form={form}
                disabled
                InputProps={{
                  endAdornment: <InputAdornment position="end">{CountryCurrency[customer?.country || ""]}</InputAdornment>
                }}
              />
            </Grid>)}
          <Grid item xs={2}
            sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
            <Button
              sx={{ mt: 1 }}
              disabled={form.value.details.length === 1}
              onClick={() => form.removeArrayElement("details", index)}
              startIcon={<DeleteIcon/>}>
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};